<template>
  <div class="sidebar bg-gray-200 w-2/12 flex flex-col">
    <div class="flex justify-center p-4">
      <img alt="Vue logo" src="@/assets/logo.svg" class="h-20" />
    </div>
    <b-dropdown aria-role="list" :close-on-click="false" expanded>
      <div class="flex items-center" slot="trigger" slot-scope="{ active }">
        <div class="p-4"><b-icon icon="account-circle-outline"></b-icon></div>
        <div class="flex flex-col justify-center">
          <div class="is-size-6">
            {{
              $store.getters.activeUser ? $store.getters.activeUser.name : ""
            }}
          </div>
          <div class="is-size-7">{{ $store.getters.userRole }}</div>
        </div>
        <div class="flex-grow"></div>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'" class="mr-4"></b-icon>
      </div>
      <b-dropdown-item aria-role="listitem" has-link>
        <router-link :to="{ name: 'Home' }" class="flex has-text-black">
          Go to website
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" has-link>
        <router-link :to="{ name: 'Account' }" class="flex has-text-black">
          Account
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" has-link>
        <a @click="logout" class="has-text-black">
          Logout
        </a>
      </b-dropdown-item>
    </b-dropdown>
    <div class="flex-grow overflow-y-scroll h-20">
      <router-link
        v-if="userHasPermission('read-dashboard')"
        :to="{ name: 'Dashboard' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="view-dashboard-outline"></b-icon>
        <span class="ml-4">Dashboard</span>
      </router-link>
      <router-link
        v-if="userHasPermission('read-orders')"
        :to="{ name: 'Orders' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="cart-outline"></b-icon>
        <span class="ml-4">Orders</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-joborders')"
        :to="{ name: 'Joborders' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="clipboard-list-outline"></b-icon>
        <span class="ml-4">Job Orders</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-items')"
        :to="{ name: 'Items' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="order-bool-ascending-variant"></b-icon>
        <span class="ml-4">Items</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-products')"
        :to="{ name: 'Products' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="toy-brick-outline"></b-icon>
        <span class="ml-4">Products</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-categories')"
        :to="{ name: 'Categories' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="group"></b-icon>
        <span class="ml-4">Categories</span></router-link
      >
      <!-- <router-link
        v-if="userHasPermission('read-costs')"
        :to="{ name: 'Costing' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="cash-usd-outline"></b-icon>
        <span class="ml-4">Costing</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-materials')"
        :to="{ name: 'Materials' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="format-list-bulleted-type"></b-icon>
        <span class="ml-4">Materials</span></router-link
      > -->
      <router-link
        v-if="userHasPermission('read-references')"
        :to="{ name: 'References' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="file-eye-outline"></b-icon>
        <span class="ml-4">References</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-files')"
        :to="{ name: 'Files' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="file-cabinet"></b-icon>
        <span class="ml-4">Files</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-users')"
        :to="{ name: 'Users' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="account-group-outline"></b-icon>
        <span class="ml-4">Users</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-roles')"
        :to="{ name: 'Roles' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="account-lock-outline"></b-icon>
        <span class="ml-4">Roles</span></router-link
      >
      <router-link
        v-if="userHasPermission('read-permissions')"
        :to="{ name: 'Permissions' }"
        class="flex p-4 capitalized has-text-black"
        active-class="bg-gray-300"
        exact
      >
        <b-icon icon="shield-lock-outline"></b-icon>
        <span class="ml-4">Permissions</span></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data: () => ({
    active: false
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    userHasPermission(data) {
      return this.$store.getters.userHasPermission(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  height: 100vh;
}
</style>
